<template>
  <PageContainer
    :title="$translations['sign-up']['employee-title']"
    :back="actions.back"
    :styles="{ background: 'white' }"
    :has-shy-header="true"
  >
    <template v-slot:content>
      <div class="segment margin-top-zero padding-top-zero">
        <div class="segment-content">
          <Skeleton
            v-if="firstLoad"
            height="24px"
            width="100%"
            borderRadius="8px"
            class="margin-bottom-lg-3"
          />

          <template v-else>
            <div class="body margin-bottom-lg-3" v-html="descriptionText" />
            <div class="onboarding-employee-form" v-if="formVisible">
              <div class="onboarding-employee-form-name">
                <InputTextField
                  v-model="form.first_name"
                  qa="onboarding-employee_first_name"
                  ref-name="onboarding-employee_first_name"
                  :label="$translations['sign-up'].labels.first_name"
                  :lang="lang"
                />
                <InputTextField
                  v-model="form.last_name"
                  qa="onboarding-employee_last_name"
                  ref-name="onboarding-employee_last_name"
                  :label="$translations['sign-up'].labels.last_name"
                  :lang="lang"
                />
              </div>
              <InputEmailField
                v-model="form.email"
                @onBlur="validateEmailUse"
                qa="onboarding-employee_email"
                ref-name="onboarding-employee_email"
                :label="$translations['sign-up'].labels.email"
                :error="Boolean(emailError)"
                :errorText="emailError"
                :lang="lang"
                type="email"
              />
              <PasswordField
                v-model="form.password"
                qa="onboarding-employee_password"
                ref-name="onboarding-employee_password"
                :label="$translations['sign-up'].labels.password"
                :lang="lang"
                :enabled-regex="true"
                regex="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])"
                @onError="verifyPassword($event)"
              />

              <PasswordValidation
                :password="form.password"
                :validationRegex="validationPassword"
              />

              <Button
                @clicked="handlerSignUp"
                class="button"
                id="registro_ok"
                qa="onboarding-employee_submit"
                :disable="formIsNotValid"
                :loading="loading"
                :text="$translations['sign-in']['sign-up-email']"
              />

              <div class="terms-and-conditions">
                <span
                  class="small text-gray-50"
                  v-html="$translations['sign-up'].terms[0]"
                />
                <span
                  @click="openTermsAndConditions"
                  class="link small text-purple"
                  v-html="$translations['sign-up'].terms[1]"
                />
                <span
                  class="small text-gray-50"
                  v-html="$translations['sign-up'].terms[2]"
                />
                <span
                  @click="openTermsAndConditions"
                  class="link small text-purple"
                  v-html="$translations['sign-up'].terms[3]"
                />
              </div>
            </div>

            <div class="onboarding-employee-cc" v-if="formCC">
              <InputTextField
                class="margin-bottom"
                v-model="cc"
                qa="onboarding-employee_first_name"
                ref-name="onboarding-employee_first_name"
                :label="$translations['sign-up'].labels.cc"
                :lang="lang"
              />
              <Button
                @clicked="handlerCCValidation"
                class="button"
                id="registro_cc_ok"
                qa="onboarding-employee_cc_submit"
                :disable="cc.length === 0"
                :loading="loading"
                :text="$translations['sign-up'].button['employee-cc-validate']"
              />
            </div>

            <div class="onboarding-employee-code" v-if="!company">
              <InputTextField
                v-model="form.company_code"
                qa="onboarding-employee_code"
                ref-name="onboarding-employee_code"
                :label="$translations['sign-up'].labels.code"
                :lang="lang"
              />

              <Button
                @clicked="validateCode"
                class="button margin-top"
                id="registro_ok"
                qa="onboarding-employee-code_submit"
                :disable="!form.company_code"
                :loading="validationLoading"
                :text="$translations['sign-up'].button['employee-validate']"
              />

              <div class="onboarding-employee-code-hint">
                <span
                  class="body text-gray-60"
                  v-html="$translations['sign-up']['employee-hint']"
                />
                <span
                  class="content-title btn-link text-purple"
                  v-html="$translations['sign-up']['employee-action']"
                  @click="companyLanding"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  InputTextField,
  PasswordField,
  InputEmailField
} from '@seliaco/sea-otter'
import {
  PageContainer,
  Button,
  validateEmail,
  Company,
  Skeleton
} from '@seliaco/red-panda'
import SignUpMixin from '@/mixins/sign-up-mixin'
import PasswordValidation from '../auth/components/PasswordValidation.vue'
import { Browser } from '@capacitor/browser'

export default {
  name: 'OnboardingEmployee',
  components: {
    PageContainer,
    Button,
    InputTextField,
    PasswordField,
    InputEmailField,
    PasswordValidation,
    Skeleton
  },
  mixins: [SignUpMixin],

  data () {
    const actions = {
      back: {
        callback: () => {
          this.$router.push({ name: 'SignUp' })
        }
      }
    }

    return {
      actions,
      emailError: null,
      company: null,
      cc: '',
      ccflow: false,
      form: {
        company_code: null,
        email: null,
        password: null,
        first_name: null,
        last_name: null
      },
      formError: {
        password: true,
        email: true
      },
      validationPassword: false,
      validationLoading: false,
      createLoading: false,
      firstLoad: true
    }
  },
  mounted () {
    const query = this.$route.query

    if (query.company_code) {
      this.loading = true
      this.form.company_code = query.company_code
      this.validateCode()
    }
    this.firstLoad = false
  },
  methods: {
    async validateCode () {
      this.validationLoading = true
      this.company = await Company.validate(this.form.company_code).catch(
        () => {
          this.$toast({
            text: this.$translations['sign-up']['employee-code-error'],
            severity: 'error'
          })
          return null
        }
      )

      this.ccflow = this.company?.company_validation

      this.validationLoading = false
      this.firstLoad = false
      this.loading = false
    },
    async handlerCCValidation () {
      this.loading = true
      await Company.validateCC({
        company: this.company.company_id,
        value: this.cc
      })
        .then(() => {
          this.ccflow = false
        })
        .catch(() => {
          this.$toast({
            text: this.$translations['sign-up']['employee-cc-error'],
            severity: 'error'
          })
          return null
        })
        .finally(() => {
          this.loading = false
        })
    },
    async handlerSignUp () {
      this.loading = true
      await this.signUp()
      this.loading = false
    },
    async validateEmailUse () {
      const unique = encodeURIComponent(this.form.email)
      const result = await this.$auth.verifyEmail(unique)
      this.emailError =
        this.form.email && !validateEmail(this.form.email)
          ? this.$translations['form-error'].email
          : !result.is_valid
          ? this.$translations['form-error'].duplicatemail
          : null
      this.formError.email = !validateEmail(this.form.email)
        ? true
        : !result.is_valid
    },

    isPasswordValid (password) {
      const passwordRegex = new RegExp(
        '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$'
      )
      return passwordRegex.test(password)
    },
    verifyPassword (event) {
      this.validationPassword = event
    },
    async openTermsAndConditions () {
      await Browser.open({ url: process.env.VUE_APP_TERMS_AND_CONDITIONS_URL })
    },
    async companyLanding () {
      await Browser.open({
        url: `https://selia.co/${this.$store.getters.language.lang}/for-business`
      })
    }
  },
  computed: {
    formCC () {
      if (!this.company) {
        return false
      }

      return this.company?.company_validation && this.ccflow
    },
    formVisible () {
      if (this.company?.company_validation && this.ccflow) {
        return false
      }

      return this.company && !this.validationLoading
    },
    descriptionText () {
      if (this.company?.company_validation && this.ccflow) {
        return this.$translations['sign-up']['employee-description-cc'].replace(
          '{company}',
          this.company.company_name
        )
      }

      if (this.company) {
        return this.$translations['sign-up'][
          'employee-description-with-name'
        ].replace('{company}', this.company.company_name)
      }

      return this.$translations['sign-up']['employee-description']
    },
    formIsNotValid () {
      return Boolean(
        !this.form.email ||
          !this.form.password ||
          !this.form.first_name ||
          !this.form.last_name ||
          this.formError.email ||
          !this.form.password ||
          !this.isPasswordValid(this.form.password)
      )
    }
  }
}
</script>

<style lang="sass">

.onboarding-employee-form
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  max-width: 480px
  gap: 8px
  &-name
    display: flex
    width: 100%
    gap: 8px
  .button
    margin-top: 8px
  .heading-display
    margin: 16px 0 40px
    text-align: center
  .forgot-password
    margin-top: 16px
    text-align: end

.onboarding-employee-code-hint
  display: flex
  margin-top: 24px
  justify-content: center
  gap: 4px
  flex-direction: column
  align-items: center
  .content-title
    cursor: pointer

.terms-and-conditions
  line-height: 1
  text-align: center
  margin-top: 32px
</style>
